import globalContentDe from '@/assets/data/de/global-content.json'
import globalContentEn from '@/assets/data/en/global-content.json'
import globalContentEs from '@/assets/data/es/global-content.json'
import globalContentFr from '@/assets/data/fr/global-content.json'
import globalContentIt from '@/assets/data/it/global-content.json'
import { emitter } from '@/emitter'
import router from '@/router'
import { useAuthStore } from '@/store/auth'
import { useMainStore } from '@/store/main'
import { db } from '@/vendor/db'

export default function useApi() {
  const getOrderReference = (options: { body: { [x: string]: { [x: string]: { [x: string]: any } }; reference: any } }) => {
    let referenceKey
    let reference

    for (const key in options.body) {
      for (const key1 in options.body[key]) {
        if (key1.toLowerCase().includes('reference')) {
          referenceKey = key1
          reference = options.body[key][key1]
        } else {
          for (const key2 in options.body[key]) {
            if (key2.toLowerCase().includes('selections')) {
              for (const key3 in options.body[key][key2]) {
                if (key3.toLowerCase().includes('reference')) {
                  referenceKey = key3
                  reference = options.body[key][key2][referenceKey]
                }
              }
            }
          }
        }
        options.body.reference = reference
      }
    }
  }

  const orderFormLocalDataUpdate = (endpointStr: string, options: any) => {
    if (!options.body.uuid) {
      const uuid = router.currentRoute.value.query.uuid
      options.body.uuid = uuid
    }

    if (options.body.order_complete) {
      options.body.order_complete = true
    }

    if (options.body.order_saved) {
      options.body.order_saved = true
    }

    if (!useMainStore().navigatorOnline && !options.body.offline_order) {
      options.body.offline_order = true
    }

    getOrderReference(options)

    if (!options.body.type_readable) {
      let typeReadable = router.currentRoute.value.name as string
      typeReadable = typeReadable.replace(/(^\w{1})|(\s{1}\w{1})/g, (match: string) => match.toUpperCase())
      typeReadable = typeReadable.replace(/([A-Z])/g, ' $1')
      options.body.type_readable = typeReadable
    }

    if (options.body.created_at) {
      let updatedAt = new Date().toISOString().slice(0, 10)
      updatedAt = updatedAt.replace(/-/g, '/')
      options.body.updated_at = updatedAt
    } else {
      let createdAt = new Date().toISOString().slice(0, 10)
      createdAt = createdAt.replace(/-/g, '/')
      options.body.created_at = createdAt
    }

    localStorage.setItem(endpointStr, JSON.stringify(options.body))
  }

  const handleApiResponse = (response: Response) => {
    if (!response.ok) {
      console.error('🔥 API Error', response)
    }
  }

  const requestApiCsrfToken = () => {
    return fetch(`${import.meta.env.VITE_LARAVEL_PATH}/sanctum/csrf-cookie`, {
      method: 'GET',
      credentials: 'include'
    })
  }

  const getApiToken = () => {
    return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent('XSRF-TOKEN').replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null
  }

  const headersNoCache = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0'
  }

  const getApiHeaders = (options?: apiCallOptions, noCache = false) => {
    let headers

    if (options && options.file) {
      headers = {
        'X-XSRF-TOKEN': getApiToken()
        // 'X-Requested-With': 'XMLHttpRequest'
      }
    } else {
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': getApiToken(),
        'Accept-Language': useAuthStore().userLocale
      }
    }

    if (noCache) {
      headers = { ...headers, ...headersNoCache }
    }

    return headers
  }

  const getLocalData = (endpointStr: string, options: apiCallOptions) => {
    if (options.method === 'POST') {
      if (options.array) {
        let item: any = localStorage.getItem(endpointStr)
        if (item) {
          // If already set, get item, push to array, then set again
          item = JSON.parse(item)
          // Find items in array that contain ids the same as router.currentRoute.value.query.uuid and remove them
          item = item.filter((item: any) => {
            return item.id !== router.currentRoute.value.query.uuid
          })

          item.push(options.body)
          localStorage.setItem(endpointStr, JSON.stringify(item))
        } else {
          // If not already set, create empty array, set item
          const arr = []
          arr.push(options.body)
          localStorage.setItem(endpointStr, JSON.stringify(arr))
        }
      } else {
        localStorage.setItem(endpointStr, JSON.stringify(options.body))
      }
      return JSON.parse(localStorage.getItem(endpointStr) as string)
    } else if (options.method === 'GET') {
      // Get data from localStorage and convert to JSON optionsect
      const item = JSON.parse(localStorage.getItem(endpointStr) as string)

      if (item) {
        return item
      } else {
        // eslint-disable-next-line no-console
        console.error(`🔥 No fallback found for ${endpointStr}`)
        return null
      }
    }
  }

  const checkOnlineStatus = () => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_LARAVEL_PATH}/api/v1/user`, {
          method: 'GET',
          credentials: 'include',
          headers: getApiHeaders() as HeadersInit
        })
        if (response.ok) {
          clearInterval(interval)
          useMainStore().reconnectPopupOverlayActive = true
          useMainStore().reconnectedPopupActive = true
        }
      } catch (error) {
        // Nothing here as to not to flood the console with constant errors
      }
    }, 5000)

    return interval
  }

  const apiCall = async (endpointStr: string, options?: apiCallOptions) => {
    useMainStore().isFetching = true

    try {
      const request = {
        method: options?.method || 'GET',
        headers: getApiHeaders(options) || {},
        body: JSON.stringify(options?.body) || null,
        credentials: 'include'
      }

      if (options?.file) {
        const blob = await fetch(options.file.file).then((r) => r.blob())
        const formData: any = new FormData()
        formData.append('test', blob, options.file.filename)
        request.body = formData
      }

      if (options?.orderForm) {
        orderFormLocalDataUpdate(endpointStr, options)
      }

      if (useMainStore().navigatorOnline) {
        const response = await fetch(`${import.meta.env.VITE_LARAVEL_PATH}/api/v1/` + endpointStr, request as any)
        const orderComplete = response.ok && options?.body?.offline_order && options?.body?.order_complete
        const orderSaved = response.ok && options?.body?.offline_order && options?.body?.order_saved

        // handleApiResponse(response)

        if (response.ok && options?.file) {
          db.files.delete(options.file.id)
          return response.text()
        } else if (orderComplete || orderSaved) {
          if (orderComplete) {
            notifyOrderComplete(options)
          } else if (orderSaved) {
            notifyOrderSaved(options)
          }

          if (router.currentRoute.value.name === 'savedOrders') {
            window.location.reload()
          }

          emitter.emit('loadLocalOrders')
          return response.json()
        } else if (!response.ok) {
          return handleErrors(response)
        } else {
          return response.json()
        }
      } else {
        if (options?.fallback) {
          return getLocalData(endpointStr, options)
        } else {
          return null
        }
      }
    } catch (error) {
      checkOnlineStatus()
      useMainStore().navigatorOnline = false

      let msg
      setTimeout(() => {
        if (useAuthStore().userLocale === 'de') {
          msg = globalContentDe.browsing_offline
        } else if (useAuthStore().userLocale === 'es') {
          msg = globalContentEs.browsing_offline
        } else if (useAuthStore().userLocale === 'fr') {
          msg = globalContentFr.browsing_offline
        } else if (useAuthStore().userLocale === 'it') {
          msg = globalContentIt.browsing_offline
        } else {
          msg = globalContentEn.browsing_offline
        }

        useMainStore().setSystemAlerts({
          type: 'warning',
          name: 'browsing-offline',
          message: [msg]
        })
      }, 0)

      console.error('🔥 API Error', error)
    } finally {
      useMainStore().isFetching = false
    }
  }

  const notifyOrderComplete = (options: apiCallOptions) => {
    let msg = `${globalContentEn.order_complete_notification_start}${options.body.reference}${globalContentEn.order_complete_notification_end}`

    if (useAuthStore().userLocale === 'de') {
      msg = `${globalContentDe.order_complete_notification_start}${options.body.reference}${globalContentDe.order_complete_notification_end}`
    } else if (useAuthStore().userLocale === 'es') {
      msg = `${globalContentEs.order_complete_notification_start}${options.body.reference}${globalContentEs.order_complete_notification_end}`
    } else if (useAuthStore().userLocale === 'fr') {
      msg = `${globalContentFr.order_complete_notification_start}${options.body.reference}${globalContentFr.order_complete_notification_end}`
    } else if (useAuthStore().userLocale === 'it') {
      msg = `${globalContentIt.order_complete_notification_start}${options.body.reference}${globalContentIt.order_complete_notification_end}`
    }

    useMainStore().setSystemAlerts({
      type: 'ok',
      name: `order-complete-notification-${options.body.uuid}`,
      options: { richText: true, dismissibleSystemAlert: true },
      message: [msg]
    })
  }

  const notifyOrderSaved = (options: apiCallOptions) => {
    let msg = `${globalContentEn.order_saved_notification_start}${options.body.reference}${globalContentEn.order_saved_notification_end}`

    if (useAuthStore().userLocale === 'de') {
      msg = `${globalContentDe.order_saved_notification_start}${options.body.reference}${globalContentDe.order_saved_notification_end}`
    } else if (useAuthStore().userLocale === 'es') {
      msg = `${globalContentEs.order_saved_notification_start}${options.body.reference}${globalContentEs.order_saved_notification_end}`
    } else if (useAuthStore().userLocale === 'fr') {
      msg = `${globalContentFr.order_saved_notification_start}${options.body.reference}${globalContentFr.order_saved_notification_end}`
    } else if (useAuthStore().userLocale === 'it') {
      msg = `${globalContentIt.order_saved_notification_start}${options.body.reference}${globalContentIt.order_saved_notification_end}`
    }

    useMainStore().setSystemAlerts({
      type: 'ok',
      name: `order-saved-notification-${options.body.uuid}`,
      options: { richText: true, dismissibleSystemAlert: true },
      message: [msg]
    })
  }

  const handleErrors = async (response: Response) => {
    if (response.status === 401 || response.status === 403 || response.status === 419) {
      localStorage.removeItem('authenticated')
      localStorage.removeItem('user')

      useAuthStore().authenticated = false
      useAuthStore().user = null

      if (import.meta.env.MODE === 'development' && import.meta.env.VITE_BYPASS_AUTH === 'true') {
        const data = await response.json()
        return data
      }

      if (router.currentRoute.value.meta.middleware === 'auth') {
        router.push({ name: 'login' })
      }
    } else {
      const data = await response.json()
      return data
    }
  }

  return {
    apiCall,
    requestApiCsrfToken,
    checkOnlineStatus,
    handleApiResponse,
    handleErrors,
    getApiHeaders,
    getApiToken
  }
}
