import { defineStore } from 'pinia'

export const useMainStore = defineStore({
  id: 'mainStore',
  state: (): MainStore => ({
    navigatorOnline: navigator.onLine,
    reconnectPopupOverlayActive: false,
    reconnectedPopupActive: false,
    isFetching: false,
    fetchingIndicatorDelay: 500,
    systemAlerts: []
  }),
  actions: {
    setSystemAlerts(payload: SystemAlert) {
      // Don't push if it already exists
      if (this.systemAlerts.find((alert) => alert.name === payload.name)) return
      this.systemAlerts.push(payload)
    },
    removeSystemAlerts(payload: SystemAlertRemove) {
      // Remove alert inside `this.systemAlerts` array that matches `payload.name`
      this.systemAlerts = this.systemAlerts.filter((alert) => alert.name !== payload.name)
    },
    fetchingIndicatorNoDelay() {
      this.fetchingIndicatorDelay = 0
    },
    fetchingIndicatorDelayReset() {
      this.fetchingIndicatorDelay = 500
    }
  }
})
