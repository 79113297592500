// Permissions are defined in the API - App\Enum\Permissions
// Spaces are replaced with underscores in the API response

export const SHOW_SPARES = 'show_spares'
export const SHOW_ULTIMATE = 'show_ultimate'
export const SHOW_ULTIMATE_SPARES = 'show_ultimate_spares'
export const SHOW_PAST_ORDERS = 'show_past_orders'
export const ALLOW_AR_SCAN = 'allow_ar_scan'
export const VIEW_CUSTOMER_PORTAL = 'view_customer_portal'
export const ORDER_A_STAIRLIFT = 'order_a_stairlift'
export const VIEW_FAQS_NEWS_DOWNLOADS = 'view_faqs_news_downloads'
export const CLM_REGISTER_STAIRLIFT = 'clm_register_stairlift'
export const CLM_STAIRLIFT_SEARCH = 'clm_stairlift_search'
export const CLM_VIEW_STAIRLIFT_DETAILS = 'clm_view_stairlift_details'
export const CLM_DETAILED_HISTORY_VIEW = 'clm_detailed_history_view'
export const CLM_VIEW_REGISTERED_STAIRLIFT_LIST = 'clm_view_registered_stairlift_list'
export const CLM_EDIT_STAIRLIFT_NOTIFICATION_RECEIVER = 'clm_edit_stairlift_notification_receiver'
export const CLM_DETAILED_TROUBLESHOOTING = 'clm_detailed_troubleshooting'
