<script setup lang="ts">
// Props
const props = defineProps({
  classes: {
    type: String,
    validator: (value: string) => {
      const validClasses = ['loading-spinner--large', 'loading-spinner--central', 'loading-spinner--colour-white']
      const classes = value.split(' ')
      return classes.every((className) => validClasses.includes(className))
    }
  }
})
</script>

<template>
  <div :class="['loading-spinner', props.classes]">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<style lang="scss" scoped>
.loading-spinner {
  display: flex;
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
}

.loading-spinner--large {
  width: calc(0.875rem * 2);
  height: calc(0.875rem * 2);
}

.loading-spinner--central {
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

.loading-spinner {
  div {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0.125rem;
    border: 0.125rem solid $c-disco;
    border-radius: 50%;
    animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $c-disco transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

.loading-spinner--large {
  div {
    border-width: calc(0.125rem * 2);
    margin: calc(0.125rem * 2);
  }
}

.loading-spinner--colour-white {
  div {
    border-color: $c-white transparent transparent transparent;
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
