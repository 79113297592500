<template>
  <div id="app">
    <SymbolsBlock />
    <RouterView :postedLocalDataResponse="postedLocalDataResponse" />
    <TransparentOverlay v-if="showLoadingSpinner">
      <LoadingSpinner :classes="'loading-spinner--large loading-spinner--central'" />
    </TransparentOverlay>
    <div class="app-overlay" v-if="showAppOverlay"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SymbolsBlock from '@/symbols/SymbolsBlock.vue'
import TransparentOverlay from '@/components/TransparentOverlay.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import requests from '@/mixins/requests'
import globalContent from '@/assets/data/en/global-content.json'
import loadLocalOrders from '@/mixins/loadLocalOrders'
import { useAuthStore } from '@/store/auth'
import { useMainStore } from '@/store/main'
import { useSparesBasketStore } from '@/store/sparesBasket'
import useApi from '@/composables/api'

const { apiCall } = useApi()

export default defineComponent({
  setup() {
    return { apiCall }
  },
  components: {
    SymbolsBlock,
    TransparentOverlay,
    LoadingSpinner
  },
  mixins: [requests, loadLocalOrders],
  data() {
    return {
      authStore: useAuthStore(),
      mainStore: useMainStore(),
      globalContent: globalContent,
      postedLocalDataResponse: [],
      localOrders: [],
      overlayTimer: 0 as any,
      showTransparentOverlay: false,
      showAppOverlay: false
    }
  },
  created() {
    this.updateApi()

    // Emitted from saved SavedOrdersView.vue
    this.emitter.on('updateApi', () => {
      this.updateApi()
    })

    // Emitted from saved SavedOrdersView.vue and api.ts
    this.emitter.on('loadLocalOrders', () => {
      this.loadLocalOrders()
    })
  },
  mounted() {
    this.loadLocalOrders()
  },
  methods: {
    async getbasketCount() {
      if (this.mainStore.navigatorOnline) {
        // Run Only if online (These don't currently have fallbacks)
        useSparesBasketStore().setBasketState(await apiCall('basket', { method: 'GET' }))
      }
    },
    async getAddresses(this: any) {
      const currentUrl = document.location.href
      if (!currentUrl.includes('/spares/checkout')) {
        // get addresses
        await this.getAddressesRequest()
      }
    },
    async updateApi() {
      if (this.mainStore.navigatorOnline && this.authStore.authenticated) {
        // These will run when the user comes back online
        const endpointsToPostTo = ['customer/address/create']

        // Push each value of `this.getLocalOrders().uuid` to array `endpointsToPostTo` if array not empty
        if (this.getLocalOrders().allItems.length) {
          for (const order of this.getLocalOrders().allItems) {
            if (order.type === 'ultimate' || order.type === 'curve') {
              if (!order.upload_files) return

              if (order.upload_files.selections) {
                const response = await this.postFileBlobs(order)

                if (!response.length || response[0].length) {
                  // If response isn't null
                  // Update order with Server ID that reference the file blob data

                  order.upload_files.selections.file_uploader = response

                  // Get order from local storage
                  const localOrderStr: string | null = localStorage.getItem(`order/${order.uuid}`)

                  if (!localOrderStr) return

                  const localOrder: OrderForm = JSON.parse(localOrderStr)

                  // Update local order with Server ID that reference the file blob data
                  localOrder.upload_files.selections.file_uploader = response

                  // Save order back to local storage
                  localStorage.setItem(`order/${order.uuid}`, JSON.stringify(localOrder))
                }
              }
            }
          }
          const { completedItems, savedItems } = this.getLocalOrders()

          if (completedItems.length) {
            for (const { uuid } of completedItems) {
              // Completed need sending to the API...
              endpointsToPostTo.push(`order/${uuid}`)
            }
          }

          if (savedItems.length) {
            for (const { uuid } of savedItems) {
              // ...and saved items need sending to the API
              endpointsToPostTo.push(`order/${uuid}`)
            }
          }
        }

        // For each endpoint, check if there is data in localStorage and post it
        endpointsToPostTo.forEach((endpoint) => {
          const data = JSON.parse(localStorage.getItem(endpoint) as string)
          const isArray = Array.isArray(data)

          if (data) {
            // this.updateForm()
            this.postFromLocalData({ endpointStr: endpoint, data: data, array: isArray })
          }
        })
      }
    }
  },
  computed: {
    showLoadingSpinner() {
      return this.showTransparentOverlay
    }
  },
  watch: {
    'mainStore.isFetching': {
      immediate: true,
      handler: function (val) {
        this.showAppOverlay = val

        clearTimeout(this.overlayTimer)
        if (val) {
          this.overlayTimer = setTimeout(() => {
            this.showTransparentOverlay = true
          }, this.mainStore.fetchingIndicatorDelay)
        } else {
          this.showTransparentOverlay = false
        }
      }
    },
    localOrders: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!this.mainStore.navigatorOnline) return

        // Return if none of the values in the array contain a `offline_order` property
        // Needed because some of the local orders are drafts that were created whilst online
        if (!val.some((item: OrderForm) => item.offline_order)) {
          this.mainStore.removeSystemAlerts({ name: 'saved-order-notification' })
          return
        }

        if (val.length) {
          this.mainStore.setSystemAlerts({
            type: 'warning',
            name: 'saved-order-notification',
            options: { richText: true, dismissibleSystemAlert: true },
            message: [this.globalContent.saved_order_notification]
          })
        } else {
          this.mainStore.removeSystemAlerts({ name: 'saved-order-notification' })
        }
      }
    },
    'authStore.authenticated': {
      immediate: true,
      handler: function (val, oldVal) {
        // Return if false i.e. user is not logged in
        if (!val) return

        if (val !== oldVal) {
          this.getbasketCount()
          this.getAddresses()
        }
      }
    }
  }
})
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  @include transition;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  @include transition(opacity, 300ms);
}

.fade-slow-enter-from,
.fade-slow-leave-to {
  opacity: 0;
}
</style>
