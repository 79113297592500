import globalContentDe from '@/assets/data/de/global-content.json'
import globalContentEn from '@/assets/data/en/global-content.json'
import globalContentEs from '@/assets/data/es/global-content.json'
import globalContentFr from '@/assets/data/fr/global-content.json'
import globalContentIt from '@/assets/data/it/global-content.json'
import useApi from '@/composables/api'
import useIsObjectEmpty from '@/composables/isObjectEmpty'
import { emitter } from '@/emitter'
import router from '@/router'
import { useAuthStore } from '@/store/auth'
import { useMainStore } from '@/store/main'
import { useSparesBasketStore } from '@/store/sparesBasket'
import { defineStore } from 'pinia'
import { v1 as uuidv1 } from 'uuid'

export const useOrderFormStore = defineStore({
  id: 'orderFormStore',
  state: (): OrderFormStore => ({
    isObjectEmpty: useIsObjectEmpty({}),
    api: useApi(),
    mainStore: useMainStore(),
    sparesBasketStore: useSparesBasketStore(),
    formData: {} as OrderForm,
    formDataReady: false,
    initialFormData: {} as OrderForm,
    apiResponse: {},
    initialStep: '',
    activeForm: '',
    activeStep: '',
    orderReference: '',
    activeStepNo: 1,
    stepsLength: 0,
    stepErrorKeys: [],
    stepsOrder: ['order_type', 'configure_stairlifts', 'power_supplies', 'extras', 'product_selection', 'bottom_of_staircase', 'top_of_staircase', 'end_user_measurements', 'optional_extras_alt', 'staircase_measurements', 'bulkhead_measurements', 'upload_files', 'fulfilment', 'completion'],
    ergoStraight: {
      constantOptionalExtraItems: [],
      correctAmountPowerSupplies: false,
      totalStairlifts: 0,
      fulfilmentTypeInstallation: false,
      requiresInstallation: false
    }
  }),
  actions: {
    resetState() {
      this.$reset()
    },
    init() {
      this.formData = JSON.parse(JSON.stringify(this.initialFormData))
      this.setActiveStep()
      this.formDataReady = true
    },
    async getExistingFormData() {
      this.mainStore.isFetching = true

      // Check localStorage for order first
      const localOrderData = localStorage.getItem(this.getOrderEndpointUrl())

      if (localOrderData !== null) {
        const localOrder = JSON.parse(localOrderData)
        this.formData = { ...this.formData, ...localOrder }
        this.formDataReady = true
      } else {
        // If no local order, fetch from API
        const response = await this.api.apiCall(this.getOrderEndpointUrl(), { method: 'GET', fallback: true })

        if (!response || response.errors) {
          router.push({ query: {} })
        } else {
          this.apiResponse = response

          // Use spread operator to merge `this.formData` and `response.data.payload`
          if (this.mainStore.navigatorOnline) {
            // If online
            this.formData = { ...this.formData, ...response.payload }
            this.apiWorkarounds()
          } else {
            // If offline
            this.formData = { ...this.formData, ...response }
          }
        }
      }

      // If validate true in query then run validation
      if (router.currentRoute.value.query.validate === 'true') {
        this.submitForm({
          validate: true,
          currentStep: ''
        })
      }

      this.setActiveStep()
      this.mainStore.isFetching = false
      this.formDataReady = true
    },
    apiWorkarounds() {
      // power_supplies should be an object, not an array
      if (this.formData.power_supplies?.power_supplies?.length === 0) {
        delete this.formData.power_supplies.power_supplies
        this.formData.power_supplies.power_supplies = {}
      }

      // Set default selections object for each step if it doesn't exist (the API doesn't return them if they're empty)
      const steps = ['product_selection', 'bottom_of_staircase', 'top_of_staircase', 'end_user_measurements', 'optional_extras_alt', 'staircase_measurements', 'bulkhead_measurements', 'upload_files', 'fulfilment', 'completion']

      for (const step of steps) {
        if (this.formData[step]) {
          if (!(this.formData[step] as any).selections) {
            ;(this.formData[step] as any).selections = {}
          }
        }
      }
    },
    async submitForm(steps: Steps) {
      // orderFormConnect

      if (steps.orderComplete) {
        this.formData.order_complete = true
      }

      if (steps.orderSaved) {
        this.formData.order_saved = true
      }

      const endpoint = this.getOrderEndpointUrl()
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: this.formData,
        fallback: true,
        orderForm: true
      }

      this.mainStore.isFetching = true
      try {
        const response = await this.api.apiCall(endpoint, options)

        this.apiResponse = response
        this.mainStore.isFetching = false

        // if (response.errors) {
        //   // eslint-disable-next-line no-console
        //   console.error(response.errors)
        //   this.formData.order_saved = false
        // }
        if (steps.orderComplete) {
          if (this.errorsExistInAnyStep()) {
            // eslint-disable-next-line no-console
            console.error('Please fix errors before continuing')
            this.formData.order_complete = false
          } else {
            if (this.mainStore.navigatorOnline) {
              // Only remove local order data if online, when offline it's needed so it can be posted when back online
              this.removeLocalOrder()
            }

            this.resetSparesBasket()
            router.push(`/order/${this.apiResponse.uuid}/success`)
          }
        } else if (steps.orderSaved) {
          if (this.errorsExistInAnyStep()) {
            // eslint-disable-next-line no-console
            console.error('Please fix errors before continuing')
            this.formData.order_saved = false
          } else {
            if (this.mainStore.navigatorOnline) {
              this.removeLocalOrder()
            }

            router.push({ name: 'savedOrders' })
          }
        } else if (steps.validate) {
          if (this.errorsExistInAnyStep()) {
            // eslint-disable-next-line no-console
            console.error('Please fix errors before continuing')
            this.formData.order_saved = false
          } else {
            this.resetSparesBasket()

            if (this.mainStore.navigatorOnline) {
              this.removeLocalOrder()
            }
          }
        } else if (!this.mainStore.navigatorOnline) {
          // If offline
          this.goToStep(steps)
        } else if (this.apiResponse.steps) {
          // If online
          if (steps.currentStep !== undefined && this.apiResponse.steps[steps.currentStep]?.status) {
            this.goToStep(steps)
          }
        } else {
          console.error('error')
        }
      } catch {
        useMainStore().navigatorOnline = false

        let msg
        setTimeout(() => {
          if (useAuthStore().userLocale === 'de') {
            msg = globalContentDe.browsing_offline
          } else if (useAuthStore().userLocale === 'es') {
            msg = globalContentEs.browsing_offline
          } else if (useAuthStore().userLocale === 'fr') {
            msg = globalContentFr.browsing_offline
          } else if (useAuthStore().userLocale === 'it') {
            msg = globalContentIt.browsing_offline
          } else {
            msg = globalContentEn.browsing_offline
          }

          useMainStore().setSystemAlerts({
            type: 'warning',
            name: 'browsing-offline',
            message: [msg]
          })
        }, 0)
      }
    },
    removeLocalOrder() {
      const order = localStorage.getItem(`order/${router.currentRoute.value.query.uuid}`)

      if (order) {
        localStorage.removeItem(`order/${router.currentRoute.value.query.uuid}`)
        console.info(`Data for endpoint \`${router.currentRoute.value.query.uuid}\` removed from localStorage`)
      }
    },
    removeLocalOrders(endpointStr: string) {
      // Get matching item from localStorage
      const item = JSON.parse(localStorage.getItem(endpointStr) as string)
      // If endpointStr string contains '/order'

      if (item) {
        localStorage.removeItem(endpointStr)
        // eslint-disable-next-line no-console
        console.info(`Data for endpoint \`${endpointStr}\` removed from localStorage`)
      }
    },
    getOrderEndpointUrl() {
      if (router.currentRoute.value.query.uuid) {
        return `order/${router.currentRoute.value.query.uuid}`
      } else {
        const uuid = uuidv1()

        router.push({
          query: {
            uuid: uuid
          }
        })

        return `order/${uuid}`
      }
    },
    goToStep(steps: Steps) {
      if (!steps.nextStep) return

      this.setStepNo(steps.nextStep)

      this.activeStep = steps.nextStep
      this.formData[steps.nextStep].activated = true

      if (steps.nextStep === 'payment') {
        emitter.emit('getPaymentMethods')
      }

      this.scrollStepIntoView(steps)
    },
    scrollStepIntoView(steps: Steps) {
      setTimeout(() => {
        const prevStep = document.getElementById(steps.currentStep)
        if (!prevStep) return

        const prevStepHeight = prevStep.offsetHeight
        if (prevStepHeight >= 100) return

        if (useIsObjectEmpty(this.apiResponse)) return
        const el = prevStep.nextElementSibling as HTMLElement
        if (!el) return

        const elOffset = el.getBoundingClientRect().top + window.pageYOffset
        const orderProgress = document.querySelector('.order-progress') as HTMLElement
        const orderProgressHeight = orderProgress ? orderProgress.offsetHeight : 0

        let val
        if (window.innerWidth < 1366) {
          const siteHeader = document.querySelector('.site-header') as HTMLElement
          const siteHeaderHeight = siteHeader ? siteHeader.offsetHeight : 0
          const orderSummary = document.querySelector('.order-blocks__order-summary') as HTMLElement
          const orderSummaryHeight = orderSummary ? orderSummary.offsetHeight : 0
          val = elOffset - orderSummaryHeight - orderProgressHeight - siteHeaderHeight
        } else {
          val = elOffset - orderProgressHeight
        }

        window.scroll({
          top: val,
          left: 0,
          behavior: 'auto'
        })
      }, 0)
    },
    async resetSparesBasket() {
      if (this.activeForm === 'spares') {
        this.sparesBasketStore.setBasketState(await this.api.apiCall('basket', { method: 'GET' }))
      }
    },
    getMostRecentStep() {
      // Reverse the stepsOrder array
      const reversedStepsOrder = [...this.stepsOrder].reverse()

      // Convert formData to an array and reverse it
      const formDataToArr = Object.entries(this.formData).reverse()

      // Find the first step in reversedStepsOrder that is activated in formData
      for (const step of reversedStepsOrder) {
        const formDataStep = formDataToArr.find((x) => x[0] === step)
        if (formDataStep && typeof formDataStep[1] === 'object' && formDataStep[1].activated) {
          return step
        }
      }

      // If no activated step is found, return the initial step
      return this.initialStep
    },
    setStepNo(step: string) {
      const ignore = ['type', 'uuid', 'reference', 'type_readable', 'created_at', 'updated_at', 'offline_order', 'order_saved', 'order_complete']

      // get object entires of formData but filter out the keys in `ignore`
      const formDataToArr = Object.entries(this.formData).filter((x) => !ignore.includes(x[0]))

      // Get currently active step index
      const activeStepIndex = formDataToArr.findIndex((x) => x[0] === step)

      // add 1 because of of based index
      activeStepIndex

      this.activeStepNo = activeStepIndex
    },
    setActiveStep() {
      const step = this.getMostRecentStep()
      this.setStepNo(step)
      this.activeStep = step
      this.formData[step].activated = true
    },
    errorsExistInAnyStep() {
      if (!this.mainStore.navigatorOnline) return false
      return Object.values(this.apiResponse?.steps ?? {}).some((step: any) => (step.errors?.length || 0) > 0 || Object.keys(step.input_errors || {}).length > 0)
    },
    setStepErrorKeys(payload: string[]) {
      // There's no need to retain the previous `stepErrorKeys`
      // as they will be overwritten by the new ones
      this.stepErrorKeys = []
      this.stepErrorKeys = payload
    },
    preventOptionalExtraItemRemoval(payload: { id?: any; push?: any; clearArray?: any }) {
      if (payload.clearArray) {
        this.ergoStraight.constantOptionalExtraItems = []
      } else if (payload.push) {
        if (!this.ergoStraight.constantOptionalExtraItems.includes(payload.id)) {
          // Push to array if it doesn't already exist
          this.ergoStraight.constantOptionalExtraItems.push(payload.id)
        }
      } else {
        // If `payload.id` is in the array `this.preventOptionalExtraItemRemoval`, remove it
        this.ergoStraight.constantOptionalExtraItems.splice(this.ergoStraight.constantOptionalExtraItems.indexOf(payload.id), 1)
      }
    },
    fulfilmentType(type: string) {
      this.ergoStraight.requiresInstallation = true

      if (type === 'installation') {
        this.ergoStraight.fulfilmentTypeInstallation = true
      } else {
        this.ergoStraight.fulfilmentTypeInstallation = false
        this.ergoStraight.requiresInstallation = false
      }
    },
    getTotalStairlifts() {
      const total = this.formData.configure_stairlifts.table.reduce((acc, item) => acc + item.qty, 0)
      this.ergoStraight.correctAmountPowerSupplies = false
      this.ergoStraight.totalStairlifts = total
    }
  },
  getters: {
    getStepNos(state) {
      const defaultNumbering = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      let steps = []

      // Only used for the front end (the numbers in circles)
      if (state.formData.type === 'ergo_straight') {
        if (state.formData.order_type.checked === 'bulk_order') {
          steps = defaultNumbering
        } else {
          steps = [1, 2, -1, 3, 4, 5, 6, 7, 8, 9, 10]
        }
      } else if (state.formData.product_selection) {
        if (state.formData.product_selection.selections.product === 'carriage_only') {
          steps = [1, -1, -1, -1, 2, -1, -1, -1, 3, 4]
        } else {
          steps = defaultNumbering
        }
      } else {
        steps = defaultNumbering
      }

      state.stepsLength = steps.length
      return steps
    },
    carriageOnlySelected(state) {
      return state.formData.product_selection?.selections?.product === 'carriage_only'
    }
  }
})
