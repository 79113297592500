import { defineStore } from 'pinia'

export const useSparesBasketStore = defineStore({
  id: 'sparesBasketStore',
  state: () => ({
    items: {},
    totalBasketItems: 0
  }),
  actions: {
    setBasketState(payload: { items: Record<string, { quantity: number }> }) {
      if (payload) {
        this.items = payload
        this.totalBasketItems = Object.values(payload.items).reduce((acc, item) => {
          // Add all the ASvalues of `quantity` inside the `items` object
          return acc + (item.quantity as number)
        }, 0)
      } else {
        this.items = {}
        this.totalBasketItems = 0
      }
    }
  }
})
