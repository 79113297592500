import globalContentDe from '@/assets/data/de/global-content.json'
import globalContentEn from '@/assets/data/en/global-content.json'
import globalContentEs from '@/assets/data/es/global-content.json'
import globalContentFr from '@/assets/data/fr/global-content.json'
import globalContentIt from '@/assets/data/it/global-content.json'
import useApi from '@/composables/api'
import { useMainStore } from '@/store/main'
import { useOrderFormStore } from '@/store/orderForm'
import { db } from '@/vendor/db'
import type { IndexableType } from 'dexie'
import { defineComponent } from 'vue'
import { useAuthStore } from '@/store/auth'

const { apiCall } = useApi()

export default defineComponent({
  setup() {
    return { apiCall }
  },
  data() {
    return {
      orderFormStore: useOrderFormStore(),
      data: {} as any,
      postedLocalDataResponse: [] as any
    }
  },
  methods: {
    storeLocal(endpointStr: string, data: any, type: string | undefined) {
      if (type === 'array') {
        const item = localStorage.getItem(endpointStr)
        if (item) {
          // If already set, get item, push to array, then set again
          const arr = JSON.parse(item) as any[]
          arr.push(data)
          localStorage.setItem(endpointStr, JSON.stringify(arr))
        } else {
          // If not already set, create empty array, set item
          const arr = [data]
          localStorage.setItem(endpointStr, JSON.stringify(arr))
        }
      } else {
        localStorage.setItem(endpointStr, JSON.stringify(data))
      }
      // eslint-disable-next-line no-console
      console.info(`Data for endpoint \`${endpointStr}\` saved to localStorage`)
    },
    async postFromLocalData(obj: { endpointStr: any; data: any; array: any }) {
      let response

      if (obj.array) {
        // If array, for each obj.data, post to API
        await Promise.all(
          obj.data.map(async (item: any) => {
            response = await apiCall(obj.endpointStr, { method: 'POST', body: item })
            this.handleAddressesCreatedOffline(obj, response, item)
          })
        )
      } else {
        response = await apiCall(obj.endpointStr, { method: 'POST', body: obj.data })
      }

      let stepErrors = false

      for (const key in response.steps) {
        // Check to see if any errors exist in any of these keys
        if (response.steps[key].errors.length) {
          stepErrors = true
          // Break out of the loop
          break
        }
      }
      // Check to see if any errors exist in any of the Objects inside `response.steps`
      // const errorsExist = response.steps.some((step) => {
      //   return step.errors.length > 0
      // })
      if (!stepErrors) {
        this.orderFormStore.removeLocalOrders(obj.endpointStr)
      } else {
        this.postedLocalDataResponse.push(response)
      }
    },
    handleAddressesCreatedOffline(obj: { endpointStr: string }, response: { data: { id: any } }, item: { id: any }) {
      if (obj.endpointStr === 'customer/address/create') {
        const localOrder = JSON.parse(localStorage.getItem(`order/${item.id}`) as string)

        if (localOrder) {
          localOrder.fulfilment.address_id = response.data.id
          localStorage.setItem(`order/${item.id}`, JSON.stringify(localOrder))

          // eslint-disable-next-line no-console
          console.info(`Order ${item.id} in localStorage updated with new address ID ${response.data.id} 😍`)

          localStorage.removeItem('customer/address/create')
        } else {
          // eslint-disable-next-line no-console
          console.error('No matching order in localStorage for address created offline 😢')
        }
      }
    },
    async getAddressesRequest() {
      const endpointStr = 'customer/address'
      const response = await apiCall(endpointStr, { method: 'GET', fallback: true })
      this.storeLocal(endpointStr, response, undefined) // Add the missing third argument
      return response
    },
    async postCreateAddress() {
      if (!useMainStore().navigatorOnline) {
        // Vue Set uuid to this.data as id
        this.data.id = this.$route.query.uuid
      }

      const endpointStr = 'customer/address/create'
      const response = await apiCall(endpointStr, { method: 'POST', body: this.data, fallback: true, array: true })

      // if (!useMainStore().navigatorOnline) {
      //   // Only need to store data if offline
      //   this.storeLocal(endpointStr, this.data, 'array')
      // } else {
      //   if (response.status >= 200 && response.status < 300) {
      //     response = await apiCall(endpointStr, { method: 'POST', body: this.data })
      //   }
      // }
      return response
    },
    getLocalOrders() {
      // Get all items from localStorage starting with `order/`
      const keys = Object.keys(localStorage).filter((key) => {
        return key.startsWith('order/')
      })

      // Get the values of the keys
      const items = keys.map((key) => JSON.parse(localStorage.getItem(key) as string))

      // Filter out any items that don't include a key of `order_saved` or `order_complete
      const toReturn = { allItems: items, completedItems: items.filter((item) => item.order_complete), savedItems: items.filter((item) => item.order_saved) }
      return toReturn
    },
    postFileBlobs(order: { upload_files: { selections: { file_uploader: any[] } } }) {
      if (!order.upload_files.selections.file_uploader) return []

      // Promise all each item in array `this.formData.selections.file_uploader
      return Promise.all(
        order.upload_files.selections.file_uploader.map(async (fileId: IndexableType) => {
          // Return if fileId is longer than 8 characters
          // (These should never be true, added for dev purposes and as a fail safe)
          // if (!fileId || fileId.length > 8) return null

          fileId = parseInt(String(fileId))
          let file = [] as any

          // Return if fileId is not a number
          if (isNaN(fileId)) return []

          // Get values matching `fileId` using dexie and await
          const setServerId = await db.files
            .where('id')
            .equals(fileId)
            .toArray()
            .then((item) => {
              file = item[0]

              if (!file.id) return []

              let msg
              setTimeout(() => {
                if (useAuthStore().userLocale === 'de') {
                  msg = globalContentDe.uploading_file
                } else if (useAuthStore().userLocale === 'es') {
                  msg = globalContentEs.uploading_file
                } else if (useAuthStore().userLocale === 'fr') {
                  msg = globalContentFr.uploading_file
                } else if (useAuthStore().userLocale === 'it') {
                  msg = globalContentIt.uploading_file
                } else {
                  msg = globalContentEn.uploading_file
                }

                useMainStore().setSystemAlerts({
                  type: 'warning',
                  name: `file-upload-notification-${file.id}`,
                  options: { richText: true },
                  message: [`<span>${msg}<span class="alert-block__loading-ellipsis">${file.filename}</span></span>`]
                })
              }, 0)
            })
            .then(async () => {
              const response = await apiCall('filepond', { method: 'POST', file: file })
              useMainStore().removeSystemAlerts({ name: `file-upload-notification-${file.id}` })

              return response
            })

          if (setServerId) {
            return setServerId
          } else {
            return []
          }
        })
      )
    }
  }
})
