import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    loadLocalOrders(this: MixinLocalOrders) {
      // Get all items from localStorage starting with `order/`
      const keys = Object.keys(localStorage).filter((key) => key.startsWith('order/'))

      // Get the values of the keys
      const values = keys
        .map((key) => {
          const item = localStorage.getItem(key)
          return item ? JSON.parse(item) : null
        })
        .filter(Boolean)

      // Exclude draft orders
      const orders = values.filter((value) => value.order_complete || value.order_saved)

      this.localOrders = orders.reverse()

      this.localOrders = orders
    }
  }
})
