<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'

// State
const transparentOverlayEl = ref()
const overlayActive = ref(true) // Component is conditionally rendered, but this makes the linter happy

onMounted(async () => {
  await nextTick()
  if (transparentOverlayEl.value) {
    transparentOverlayEl.value.style.opacity = '0'

    setTimeout(() => {
      if (transparentOverlayEl.value) {
        transparentOverlayEl.value.style.opacity = '1'
      }
    }, 0)
  } else {
    console.error("Element 'transparentOverlayEl' not found.")
  }
})
</script>

<template>
  <transition>
    <div class="transparent-overlay" ref="transparentOverlayEl" v-if="overlayActive">
      <slot />
    </div>
  </transition>
</template>

<style scoped lang="scss">
.transparent-overlay {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($c-black, 0.2);
  z-index: $z-transparent-overlay;
}
</style>
